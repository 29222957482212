import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import siteImageIcon from '../../../images/icon-large.png';

const SEO = ({ location, description, lang, meta, title, isArticle }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            name 
            title
            description
            metaDescription
            twitterUsername
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.metaDescription;
  const defaultTitle = site.siteMetadata?.title;
  const finalTitle = title ? `${title} | ${defaultTitle}` : defaultTitle;
  const url = `${site.siteMetadata.siteUrl}${location?.pathname || ''}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={finalTitle}
      meta={[
        {
          name: `author`,
          content: site.siteMetadata?.name || '',
        },
        {
          name: `robots`,
          content: 'index,follow', // this is the default behaviour and it can be removed -- but i kept it for understanding SEO
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:locale`,
          content: `en`,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata?.name || '',
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:title`,
          content: finalTitle,
        },
        {
          property: `og:image`,
          content: siteImageIcon,
        },
        {
          property: `og:image:width`,
          content: '414',
        },
        {
          property: `og:image:height`,
          content: '414',
        },
        {
          property: `og:image:alt`,
          content: 'Site icon',
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: isArticle ? `article` : `website`,
        },
        {
          property: `twitter:card`,
          content: `summary`,
        },
        {
          property: `twitter:creator`,
          content: site.siteMetadata?.twitterUsername || ``,
        },
        {
          property: `twitter:title`,
          content: finalTitle,
        },
        {
          property: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: siteImageIcon,
        },
        {
          property: `twitter:image:ult`,
          content: 'Site icon',
        },
      ].concat(meta)}
      link={[
        {
          // also see `gatsby-plugin-sitemap` in gatsby-config.js
          rel: 'sitemap',
          type:'application/xml',
          href: '/sitemap.xml',
        },
        isArticle && {
          rel: 'canonical',
          href: url,
        }
      ].filter(Boolean)}
    >
      {/* JSON ld content: for seo */}
      <script type='application/ld+json'>
        {`
          {
            "@context": "http://schema.org",
            "@type": "Person",
            "name": "Huzaifa Rasheed",
            "jobTitle": "Software Engineer",
            "url": "https://rhuzaifa.com/",
            "sameAs": [
              "https://twitter.com/huzRasheed",
              "https://github.com/huzaifa-99",
              "https://linkedin.com/in/huzaifa-rasheed",
              "https://stackoverflow.com/users/12579290/huzaifa"
            ]
          }
        `}
      </script>
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  isArticle: PropTypes.bool,
};

export default SEO;
